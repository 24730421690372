import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatGridList } from '@angular/material/grid-list';
import { Router } from '@angular/router';
import { Console } from 'console';
import { Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { SidemeduFacadeService } from '../side-menu/sidemedu-facade.service';
import { MosaicMenuFacadeService } from './mosaic-menu-facade.service';


export interface Tile {
  color: string;
  cols?: number;
  rows: number;
  text: string;
  image?: string;
}

@Component({
  selector: 'app-mosaic-menu',
  templateUrl: './mosaic-menu.component.html',
  styleUrls: ['./mosaic-menu.component.scss']
})
export class MosaicMenuComponent implements OnInit, AfterContentInit{

  @ViewChild('grid', { static: true }) grid: MatGridList;
  cols: Subject<any> = new Subject();
  public breakpoint: number;
  public suscripciones: Subscription = new Subscription();
  
  tiles: Tile[] = [
    { text: '', rows: 1, color: 'lightblue', image:'https://www.flexxus.com.ar/wp-content/uploads/2019/02/25e2f30a103dfee5ee8d753f84e399a2.jpg' },
    { text: 'Contabilidad', rows: 1, color: 'lightblue', image: 'https://economipedia.com/wp-content/uploads/Tipos-de-contabilidad-1.jpg' },
    { text: '', rows: 2, color: 'lightgreen', image: 'https://previews.123rf.com/images/merznatalia/merznatalia1510/merznatalia151001411/46269696-carrito-de-compras-con-iconos-de-software-de-aplicaci%C3%B3n-en-la-computadora-port%C3%A1til.jpg' },
    { text: 'Recursos Humanos', rows: 1, color: 'lightpink' },
    { text: 'Lotificación', rows: 1, color: '#DDBDF1' },
  ];
  public gridByBreakpoint = {
    xl: 3,
    lg: 3,
    md: 3,
    sm: 2,
    xs: 2
  }


  constructor(private observableMedia: MediaObserver, public mosaicMenu: MosaicMenuFacadeService, public authService:AuthService, public sideMenuFacade:SidemeduFacadeService, public router:Router) {
    this.cols.next(4);
    this.mosaicMenu.mostrarModulo('');
    
  }
  
  ngOnInit() {
    this.cols.subscribe((result) => {
      
    });
  }

  ngAfterContentInit() {
    this.observableMedia.asObservable().subscribe((change: MediaChange[]) => {
      this.cols.next(this.gridByBreakpoint[change[0].mqAlias]);
    });
  }

  salir() {
    this.authService.CerrarSesion();
  }

  navegar(item) {
    localStorage.setItem("idModulo", item.idModule);
    let menu = JSON.parse(localStorage.getItem("menusTotales"));
    menu = menu.filter(m => m.idModulo === item.idModule);
    this.sideMenuFacade.setearMenusFiltrado(menu);
    this.router.navigateByUrl('/dashboard');
  }
  styleObject(item){

    // let css;
    // let cssStyle = {};

    // if(css != undefined){
    //   css.array.forEach(element => {
    //     Object.assign(cssStyle, element);
    //   });
    // }
    let images = '../../../assets/images/Dashboard/Creditos.jpg'
    return {'background-image': 'url(' + item.image + ')', 'color': 'white', 'font-weight': 'bold'};
    // return {'background-image': 'url(' + item.image + ')', 'color': 'white', 'font-weight': 'bold'};
  }
}
