import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SeguridadService } from 'src/app/services/seguridad/seguridad.service';
import { MensajesHttpService } from 'src/app/utils/mensajesHttp/mensajes-http.service';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import { DataApi } from 'src/app/Model/dataApi';

@Injectable({
  providedIn: 'root'
})
export class RecuperarUsuarioFacadeService {

  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  constructor(private _dataApi: SeguridadService, private _mensajesHttp: MensajesHttpService,
    private _toast: ToastrServiceLocal) { }

  ObtenerUsuario(params: any, callback) {
    this.Cargando$.next(true);
    const request$ = this._dataApi.GetDataApi(`/${environment.site}/api/sec/${environment.processEnv}/v1/recuperarpass/noExpediente/`, params).pipe(
      tap((result: DataApi | any) => {
        callback(result.data.table0[0])
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        callback();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrió un error al recuperar el numero de colegiación', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );

    return request$.subscribe();
  }

}
