import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CambioPasswordFacadeService } from 'src/app/layout/cambio-password/cambio-password-facade.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import * as sha1 from 'sha1';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-recuperacion-password',
  templateUrl: './recuperacion-password.component.html',
  styleUrls: ['./recuperacion-password.component.css']
})
export class RecuperacionPasswordComponent implements OnInit {

  public hide1 = true;
  public hide2 = true;
  public hide3 = true;
  
  public claveForm: FormGroup;
  public datosUsuario:any = {};
  public token:string;
  constructor(public cambioPasswordFacade: CambioPasswordFacadeService, public activatedRoute: ActivatedRoute, private _toast: ToastrServiceLocal, public router:Router, public auth:AuthService) {
    this.activatedRoute.queryParams.subscribe(params => {
      // localStorage.setItem("token_go504", JSON.stringify(params.token));
      this.token = params.token;
      console.log('this.token',this.token)
      this.cambioPasswordFacade.validarToken(this.token, (result)=>{
        console.log('tokenResult',result)
        this.datosUsuario = result.data.table0[0];
        console.log(this.datosUsuario)
      })
    });

    this.claveForm = new FormGroup({
      repetirPass: new FormControl(''),
      passNuevo: new FormControl(''),
      usuario: new FormControl('')
    });
  }

  ngOnInit(): void {
  }

  public suscripciones:Subscription = new Subscription();
  cambiarPassword(){
    if (this.validarFormularioPass() === false) {
      return;
    }
    this.cambioPasswordFacade.cambioClave({
      usuario: this.datosUsuario?.Usuario || '',
      valor: [
        {
          passActual: '',
          token: this.token,
          passNuevo: sha1(this.claveForm.value.passNuevo),
          usuario: this.datosUsuario?.Usuario || ''
        }
      ]
    });

    this.suscripciones.add(
      this.cambioPasswordFacade.responseAction$.subscribe((result)=>{
        this.router.navigateByUrl('/public-pages/files/login');
      })
    )
  }

  validarFormularioPass() {
    if (this.claveForm.invalid) {
      this.claveForm.markAllAsTouched();
      this._toast.mensajeWarning('', 'Es necesario completar los campos requeridos');
      return false;
    } 
    else if (this.claveForm.value.repetirPass != this.claveForm.value.passNuevo) {
      this.claveForm.markAllAsTouched();
      this._toast.mensajeWarning('', 'Las contraseñas ingresadas no coinciden');
      return false
    }
    else if (this.claveForm.value.passActual === this.claveForm.value.passNuevo) {
      this.claveForm.markAllAsTouched();
      this._toast.mensajeWarning('', 'La nueva contraseña debe de ser distinta a la anterior');
      return false
    }
    else {
      return true;
    }
  }

  navegar(){
    this.router.navigateByUrl('/public-pages/files/login');
  }
}
