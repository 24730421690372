


<mat-card *ngIf="loading" style="margin: 1%;">
    <div >
         <app-loading [data]="4"></app-loading> 
    </div>
</mat-card>
<mat-card *ngIf="!loading">
    <div class="row">
        <div class="col-lg-12">
            <div  >
                <iframe [src]="url |safe"  width="100%" height="1000px" ></iframe>

            </div>
        </div>
    </div>

</mat-card>
