<div class="contenedorPadre">
    <mat-card class="container">
        <mat-card-content>
            <div class="img-signup" col-sm-12 col-md-12 col-lg-6>
                <img src="assets/images/undraw_authentication_fsn5.svg" class="img-fluid">
               
            </div>
         
            <div class="forms-container">
              
                <div class="closeAlert">
                    <button mat-mini-fab class="buttonPrincipal" (click)="navegar()">
                        <mat-icon>exit_to_app</mat-icon>
                    </button>
                </div>
              
                <div class="signin-signup">

                    <form [formGroup]="claveForm" class="sign-in-form">
                       
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <h2 class="title">Cambio Contraseña</h2>
                            </div>
                        
                        
                            <mat-form-field appearance="legacy" class="col-sm-12 col-md-12 col-lg-12">
                                <mat-label>Contraseña Actual</mat-label>
                                <input matInput [type]="hide1 ? 'password' : 'text'" placeholder="Contraseña Actual"
                                    formControlName="passActual" autocomplete="false" required>
                                <button type="button" mat-icon-button matSuffix (click)="hide1 = !hide1"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                                    <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>


                            <mat-form-field appearance="legacy" class="col-sm-12 col-md-12 col-lg-12">
                                <mat-label>Nueva Contraseña</mat-label>
                                <input matInput [type]="hide2 ? 'password' : 'text'" placeholder="Nueva Contraseña"
                                    formControlName="passNuevo" autocomplete="false" required>
                                <button type="button" mat-icon-button matSuffix (click)="hide2 = !hide2"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                    <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-hint style="color: red;" *ngIf="claveForm.get('passNuevo').invalid"> La contraseña no cumple con los criterios</mat-hint>

                            </mat-form-field>


                            <mat-form-field appearance="legacy" class="col-md-12">
                                <mat-label>Repetir Nueva Contraseña</mat-label>
                                <input matInput [type]="hide3 ? 'password' : 'text'"
                                    placeholder="Repetir Nueva Contraseña" formControlName="repetirPass"
                                    autocomplete="false" required>
                                <button type="button" mat-icon-button matSuffix (click)="hide3 = !hide3"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide3">
                                    <mat-icon>{{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-hint style="color: red;" *ngIf="claveForm.get('repetirPass').invalid"> La contraseña no cumple con los criterios</mat-hint>

                            </mat-form-field>
                            <div class="alert alert-secondary col-sm-12 col-md-12 col-lg-12"  role="alert">
                                La constraseña debe contener:
                                <ul>
                                    <li>8 caracteres de longitud.</li>
                                    <li>Letras minusculas.</li>
                                    <li>Letras mayúsculas.</li>
                                    <li>Números.</li>
                                    <li>Caracteres especiales.</li>
                                </ul>
    
                            </div>
                        </div>

                        

                        <div class="text-center">
                            <button mat-raised-button class="buttonPrincipal" (click)="cambiarPassword()">
                                Guardar</button>
                        </div>

                    </form>

                </div>
            </div>

        </mat-card-content>
    </mat-card>
</div>