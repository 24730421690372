import { HttpErrorResponse, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  public token: string;
  constructor(private router: Router, public auth:AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    this.token = localStorage.getItem('token_go504');

    if (this.token == null) {
      this.token = '';
    }

    let request = req;
    // request = req.clone({ headers: req.headers.set('Access-Control-Allow-Origin','*')})
    // request = req.clone({ headers: req.headers.set('Access-Control-Request-Headers','*')})
    // request = req.clone({ headers: req.headers.set('Content-Type','application/json')})
    // request = req.clone({ headers: req.headers.set('Content-Encoding','gzip')})


 
    if (this.token) {
      request = req.clone({
        setHeaders: {
          'x-api-key': 'SLLezg7leN10hFGnCZAgB1r0zt8CYTxV87JsYQRu',
          authorization: 'Bearer ' + this.token,
         
        }
        
      });


    }
 

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.auth.CerrarSesion();
        }

        return throwError(err);

      })
    );
  }
}
